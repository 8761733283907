import {
  IPreviewConversation,
  IPreviewQueue,
  IPreviewVoiceCall,
  IPreviewVoiceRoute,
  Nullable
  , IPreviewVoiceAsterisk, IPreviewUser, Undefinable } from 'atlas-shared';

export interface IAddGlobalStateProps {
  name: string;
  id: string | number;
  values: any;
}

export enum GlobalActionTypes {
  ADD_STATE = 'addState',
  REMOVE_STATE = 'removeState',
  SET_THEME = 'SET_THEME',
  SET_FOCUSED_CONVERSATION = 'SET_FOCUSED_CONVERSATION',
  SET_HANDLING_CONVERSATION = 'SET_HANDLING_CONVERSATION',
  HIDE_STATS = 'HIDE_STATS',
  HIDE_DASHBOARD_LEFT = 'HIDE_DASHBOARD_LEFT',
  ADD_LOADER = 'ADD_LOADER',
  REDUCE_LOADER = 'REDUCE_LOADER',
  SET_GLOBAL_VOICE_MIC = 'SET_GLOBAL_VOICE_MIC',
  SET_GLOBAL_VOICE_VOLUME = 'SET_GLOBAL_CALL_VOLUME',
  SET_GLOBAL_SEARCH = 'SET_GLOBAL_SEARCH',
  SET_GLOBAL_CALL = 'SET_GLOBAL_CALL',
  SET_GLOBAL_CALLS = 'SET_GLOBAL_CALLS',
  SET_GLOBAL_VOICE_QUEUE_ROUTE_SELECTED = 'SET_GLOBAL_VOICE_QUEUE_ROUTE_SELECTED',
  SET_GLOBAL_VOICE_QUEUE_ROUTE_OPTIONS = 'SET_GLOBAL_VOICE_QUEUE_ROUTE_OPTIONS',
  RESET_PASSWORD = 'RESET_PASSWORD',
  DISPLAY_AUDIO_SETTINGS = 'DISPLAY_AUDIO_SETTINGS',
  SET_DEVICES = 'SET_DEVICES',
  SET_DEVICES_AVAILABLE = 'SET_DEVICES_AVAILABLE',
  SET_RESET_CONVERSATION_LIST = 'SET_RESET_CONVERSATION_LIST',
  SET_GLOBAL_INACTIVE = 'SET_GLOBAL_INACTIVE',
  SET_SOCKET_DISCONNECTED = 'SET_SOCKET_DISCONNECTED',
  SET_GLOBAL_FULLSCREEN = 'SET_GLOBAL_FULLSCREEN',
  ADD_GLOBAL_POPUP_MODAL = 'ADD_GLOBAL_POPUP_MODAL',
  REMOVE_GLOBAL_POPUP_MODAL = 'REMOVE_GLOBAL_POPUP_MODAL',
  DISPLAY_CHANGELOG = 'DISPLAY_CHANGELOG',
  DOCS_PREVIEW = 'DOCS_PREVIEW',
  SWITCHING_PROFILE = 'SWITCHING_PROFILE',
  KEY_PRESSED = 'KEY_PRESSED'
}

export interface IVoiceQueueRouteOption {
  queue: IPreviewQueue;
  route: IPreviewVoiceRoute;
}

export interface IVoiceQueueRouteSelected {
  queue_id: IVoiceQueueRouteOption['queue']['id'];
  route_id: IVoiceQueueRouteOption['route']['id'];
}

interface IGlobalCallInterface {
  confirm?: boolean;
  voice_asterisk_id: IPreviewVoiceAsterisk['id'];
  numbers: Array<string>;
  requires_route: boolean;
  conversation_id?: IPreviewConversation['id'];
  headers?: Array<string>;
  transfer?: IPreviewVoiceCall;
}

export type TGlobalCallsInterface = IGlobalCallInterface & { title: string | React.ReactNode };

export interface IGlobalPopupModal {
  key: string;
  path: string;
  component: string;
  item_text?: string;
  props?: Record<string, any>;
  onFormChange?: (values: any) => void;
  onFormChangePayload?: (values: any) => any;
  formInitialValues?: Record<string, any>;
}

export interface IDocumentPreview {
  uri: string;
  filename: string;
  download_uri?: string;
}

export interface IGlobalKeyPressed {
  code: KeyboardEvent['code'];
  keyCode: KeyboardEvent['keyCode'];
  which: KeyboardEvent['which'];
  contentEditable: boolean;
  className: string;
  tagName: string;
}

export interface IGlobalStore {
  loaders: number;
  theme: 'light' | 'dark';
  hideStats: boolean;
  hideDashboardLeft: boolean;
  globalSearch: string;
  focused_conversation: Nullable<IPreviewConversation['id']>;
  handling_conversation: Nullable<IPreviewConversation['id']>;
  call: Nullable<IGlobalCallInterface>;
  calls: Nullable<Array<TGlobalCallsInterface>>;
  voice_queue_route_selected: Nullable<IVoiceQueueRouteSelected>;
  voice_queue_route_options: Array<IVoiceQueueRouteOption>;
  voice_volume: number;
  voice_mic: boolean;
  reset_password: Nullable<IPreviewUser['id']>;
  display_audio_settings: boolean;
  devices: {
    ringtone: string;
    speaker: string;
    microphone: string;
  };
  devices_available: {
    ringtone: boolean;
    speaker: boolean;
    microphone: boolean;
  };
  reset_conversation_list: Nullable<number>;
  inactive: boolean;
  socket_disconnected: boolean;
  is_print: boolean;
  is_fullscreen: boolean;
  popup_modals: Array<IGlobalPopupModal>;
  display_changelog: boolean;
  docs_preview: Nullable<{docs: Array<IDocumentPreview>; index: number}>;
  switching_profile: boolean;
  key_pressed: Undefinable<IGlobalKeyPressed>;
}
