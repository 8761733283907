import { PointsSystemActions } from '@Store';
import { IPreviewPointsSystem } from 'atlas-shared';
import { fetchPointsSystems } from '@Api';

export const actionFetchPointsSystems = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPointsSystems());
    const points_systems = await fetchPointsSystems();

    dispatch(actionSetPointsSystems(points_systems));
    return points_systems;
  };
};

export const actionLoadingPointsSystems = () => ({
  type: PointsSystemActions.LOADING,
  payload: []
});

export const actionSetPointsSystems = (payload: Array<IPreviewPointsSystem>) => ({
  type: PointsSystemActions.SET_STATE,
  payload: payload
});

export const actionAddPointsSystem = (payload: IPreviewPointsSystem) => ({
  type: PointsSystemActions.ADD_STATE,
  payload: payload
});

export const actionUpdatePointsSystem = (payload: IPreviewPointsSystem) => ({
  type: PointsSystemActions.UPDATE_STATE,
  payload: payload
});

export const actionRemovePointsSystem = (id: IPreviewPointsSystem['id']) => ({
  type: PointsSystemActions.REMOVE_STATE,
  payload: id
});
