import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  ConnectionLineType,
  Controls,
  EdgeTypes,
  MiniMap,
  NodeTypes,
  useEdgesState,
  useNodesState,
  useStoreApi,
  useUpdateNodeInternals,
  useViewport
} from 'react-flow-renderer';
import './styles.scss';
import {
  ChannelEnum, ConversationLastMessageByEnum,
  ConversationPriorityOptions,
  ConversationStatusEnum,
  ConversationStatusOptions,
  DirectionEnum,
  EnumOptions,
  EnumToArray,
  FieldTypeEnum,
  IJourneyDraftPayloadLinksKeyProperty,
  IJourneyDraftPayloadNodesKeyProperty,
  IJourneyEdgeError,
  IJourneyNodeError,
  IJourneyPayloadLinksKeyProperty,
  IJourneyPayloadNodesKeyProperty,
  IPreviewJourneyDraft,
  ISharedJourney,
  ISharedJourneyDraft,
  JourneyActionEnum,
  SignatureTypeEnum,
  SlaTimeUnitEnum,
  Undefinable,
  validateJourneyNode,
  VoiceCallBridgeExitReasonEnum, VoiceLanguageEnum
} from 'atlas-shared/dist';
import { Form } from 'antd';
import {
  AudioPlayer,
  clone,
  entitiesToOptions,
  entityToOption,
  filterRelated,
  FormModal,
  getActionIconText,
  IJourneyActionProps,
  isAgent,
  nestedArray
} from '@Utils';
import { useTranslation } from 'react-i18next';
import { createConnector, createNode, getActionProps } from './index';
import NodeView from './node.view';
import JourneysSideList from './journeys.side.list';
import { fetchJourney, streamSoundFilename } from '@Api';
import { AtlasForm, CustomFormItemProps, FormElementTypes, FormLayoutTypes } from 'atlas-form';
import {
  useCannedAnswers,
  useClients,
  useDispositions,
  useFields,
  useFormWebsites,
  useJourneies,
  useJourneyDrafts,
  useJourneyVariables,
  useMailAccounts,
  useOperationalHourses,
  useOrganizations,
  useQueues,
  useSignatures, useSkills,
  useSounds,
  useTags,
  useUsers,
  useVoiceAsterisks,
  useVoiceRoutes,
  useVoiceVoicemails
} from '@Hooks';
import { fromJson } from 'json-joi-converter';
import EdgeView from './edge.view';
import debounce from 'lodash/debounce';
import { useAppDispatch } from '@Store';

interface IEntryNode {node: IJourneyDraftPayloadNodesKeyProperty; links: Array<{link: IJourneyDraftPayloadLinksKeyProperty; node: IEntryNode}>}
interface IFlattenedEntryNode {node: IEntryNode['node']; link: IJourneyDraftPayloadLinksKeyProperty}

const getEntryNodes = (journeyValue: ISharedJourneyDraft['payload'], node: IJourneyDraftPayloadNodesKeyProperty, lvl: number = 0): IEntryNode => {
  const items: IEntryNode = { node, links: [] };

  for (const k in journeyValue.links) {
    const link = journeyValue.links[k];

    if (link.from.nodeId === node.id) {
      items.links.push({
        link,
        node: lvl < 10 && journeyValue.nodes[link.to.nodeId] ? getEntryNodes(journeyValue, journeyValue.nodes[link.to.nodeId], lvl + 1) : { node, links: [] }
      });
    }

  }

  return items;
};

interface IEntryNodeSectionJourney {links: Array<IFlattenedEntryNode>}
interface IEntryNodeSection {entry_node: IEntryNode['node']; journies: Array<IEntryNodeSectionJourney>; channel?: ChannelEnum}

/*
const flattenEntryNodeNodes = (entry_node: IEntryNode, history: Array<IFlattenedEntryNode> = []): Array<IFlattenedEntryNode> => {
  // @ts-ignore
  return entry_node.links.map(link => {
    const base = { node: link.node!.node, link: link.link };

    history.push(base);

    return link?.node?.links.length ? flattenEntryNodeNodes(link.node, [...history]) : history;
    //return [{ node: link.node!.node, link: link.link }, ...(link.node ? flattenEntryNode(link.node) : [])];
  }).flat();
};

const flattenEntryNode = (entry_node: IEntryNode): Array<{links: Array<IFlattenedEntryNode>}> => {
  return entry_node.links.map(link => {
    const history: Array<IFlattenedEntryNode> = [{ node: link.node!.node, link: link.link }];

    return { links: link?.node?.links.length ? flattenEntryNodeNodes(link.node, history) : history };
  });
};
*/

const flattenEntryNode = (entry_node: IEntryNode, history: Array<IFlattenedEntryNode> = []): Array<{links: Array<IFlattenedEntryNode>}> => {
  return entry_node.links.map(link => {
    const _history = [...history, { node: link.node!.node, link: link.link }];

    return link?.node?.links.length ? (flattenEntryNode(link.node, _history) as any).flat() : { links: _history };
  }).flat();
};

const flattenEntryNodes = (entry_nodes: Array<IEntryNode>): Array<IEntryNodeSection> => {
  return entry_nodes.map(entry_node => ({
    entry_node: entry_node.node,
    journies: flattenEntryNode(entry_node),
    channel: getActionProps(entry_node.node.action)?.channels?.[0]
  }));
};

type TErrorLink = IFlattenedEntryNode & {error: boolean};
const errorEntryNodes = (entry_nodes: Array<IEntryNodeSection>) => {
  const errors: Array<{entry_node: IEntryNode['node']; channel?: ChannelEnum; journey: Array<TErrorLink>}> = [];

  for (const { entry_node, channel, journies: journy } of entry_nodes) {

    for (const { links } of journy) {
      const journey: Array<TErrorLink> = [];
      let has_error = false;

      for (const { link, node } of links) {
        const action_node = getActionProps(node.action);
        const error = !!channel && (!!action_node.channels && !action_node.channels?.includes(channel));

        journey.push({ node, link, error: has_error ? false : true });
        has_error = has_error || error;

      }

      if (has_error)
        errors.push({ entry_node, channel, journey });
    }

  }

  return errors;
};

const connectionLineStyle = { stroke: 'var(--blue-main)', strokeWidth: 4 };
const snapGrid: [number, number] = [20, 20];
const nodeTypes: NodeTypes = {
  'input-only': NodeView,
  'output-only': NodeView,
  'input-output': NodeView,
  'default': NodeView
};

const edgeTypes: EdgeTypes = {
  edge: EdgeView
};

interface IProps extends CustomFormItemProps {
  value: ISharedJourneyDraft['payload'];
  onChange: (payload?: any)=> void;
  locked: boolean;
  entity: Record<string, any>;
}
export const JourneyViewLocked = (props: Omit<IProps, 'locked'>) => {
  return JourneyView({ ...props, locked: true });
};

export const JourneyView = ({ onChange, locked, entityId, entity, value: _value }: IProps) => {

  const highlight = new URLSearchParams(window.location.search).get('highlight');
  const { t } = useTranslation();
  // const instanceStore = useStore();
  // const { transform } = instanceStore;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const storeApi = useStoreApi();
  const viewport = useViewport();
  const [draft, setDraft] = useState<Undefinable<IPreviewJourneyDraft>>();
  const [journey, setJourney] = useState<Undefinable<ISharedJourney>>();
  const journey_drafts = useJourneyDrafts();
  const [options, setOptions] = useState<any>();
  const [journeyValue, __setJourneyValue] = useState<ISharedJourneyDraft['payload']>(_value);
  const isDraft = !('channels' in (entity || {}));
  const [value] = useState<ISharedJourneyDraft['payload']>(_value);
  const updateNodeInternals = useUpdateNodeInternals();
  const [antForm] = Form.useForm();

  const users = useUsers();
  const queues = useQueues();
  const clients = useClients();
  const skills = useSkills();
  const organizations = useOrganizations();
  const dispositions = useDispositions();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const voice_asterisks = useVoiceAsterisks();
  const form_websites = useFormWebsites();
  const canned_answers = useCannedAnswers();
  const operational_hourses = useOperationalHourses();
  const journeies = useJourneies();
  const fields = useFields();
  const tags = useTags();
  const signatures = useSignatures();
  const sounds = useSounds();
  const journey_variables = useJourneyVariables();
  const voice_voicemails = useVoiceVoicemails();
  const dispatch = useAppDispatch();

  const store = useMemo(() => ({
    users,
    queues,
    organizations,
    dispositions,
    mail_accounts,
    form_websites,
    canned_answers,
    operational_hourses,
    journeies,
    fields,
    tags,
    sounds,
    journey_variables,
    voice_voicemails
  }), [
    users,
    queues,
    organizations,
    dispositions,
    mail_accounts,
    form_websites,
    canned_answers,
    operational_hourses,
    journeies,
    fields,
    tags,
    sounds,
    journey_variables,
    voice_voicemails
  ]);

  const loaders = [
    !!entityId,
    (!isDraft || journey),
    signatures,
    journeies,
    journey_drafts,
    dispositions,
    organizations,
    queues,
    mail_accounts,
    form_websites,
    journeies,
    canned_answers,
    operational_hourses,
    sounds,
    journey_variables,
    voice_routes,
    voice_asterisks,
    voice_voicemails
  ];

  /* eslint-disable react-hooks/exhaustive-deps */
  const setJourneyValue = useCallback(debounce((v: ISharedJourneyDraft['payload']) => {
    __setJourneyValue(v);
  }, 250), []);

  useEffect(() => {
    if ((draft && journey) || !entityId || !journey_drafts || !journeies)
      return;

    let _draft: IPreviewJourneyDraft;

    if (isDraft)
      _draft = journey_drafts.dict[entityId];
    else
      _draft = journeies.dict[entityId] as any;

    if (!_draft)
      return;

    setDraft(_draft);

    if (isDraft)
      fetchJourney(_draft.journey_id).then(original_journey => {
        setJourney(original_journey);
      });
  }, [journey_drafts, journeies, draft, isDraft, journey, entityId]);

  useEffect(() => {
    const { x, y, zoom } = viewport;

    if ([x, y, zoom].join() !== [journeyValue.offset.x, journeyValue.offset.y, journeyValue.scale].join())
      setJourneyValue({
        ...journeyValue,
        scale: zoom,
        offset: { x, y }
      });
  }, [viewport, journeyValue, setJourneyValue]);

  // loaders & options
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {

    if (!loaders.every(Boolean))
      return;

    const organization_id = (journey?.organization_id || draft?.organization_id) as ISharedJourney['organization_id'];
    const journey_options = filterRelated(organization_id, 'journey', 'journey', journeies.journeies, true, it => it.id !== draft?.journey_id);
    const disposition_options = filterRelated(organization_id, 'disposition', 'journey', nestedArray<any>(dispositions.dispositions, 'parents', true, true), true);

    setOptions({
      disposition_options: filterRelated(organization_id, 'disposition', 'journey', dispositions.dispositions, true),
      disposition_tree_options: entitiesToOptions(filterRelated(organization_id, 'disposition', 'journey', nestedArray<any>(dispositions.dispositions, 'parents', true, true)), 'disposition', undefined, true, {}),
      organization_options: entitiesToOptions(organizations.organizations),
      signature_options: filterRelated(organization_id, 'signature', 'journey', signatures.signatures.filter(signature => signature.type === SignatureTypeEnum.Queue), true),
      queue_options: filterRelated(organization_id, 'queue', 'journey', queues.queues, true),
      client_options: filterRelated(organization_id, 'client', 'journey', clients.clients, true),
      skill_options: filterRelated(organization_id, 'skill', 'journey', skills.skills, true),
      mail_account_options: filterRelated(organization_id, 'mail_account', 'journey', mail_accounts.mail_accounts, true),
      voice_route_options: filterRelated(organization_id, 'voice_route', 'journey', voice_routes.voice_routes, true),
      voice_voicemail_options: filterRelated(organization_id, 'voice_voicemail', 'journey', voice_voicemails.voice_voicemails, true),
      voice_asterisk_options: entitiesToOptions(voice_asterisks.voice_asterisks),
      form_website_options: filterRelated(organization_id, 'form_website', 'journey', form_websites.form_websites, true),
      asterisk_sound_options: filterRelated(organization_id, 'sound', 'journey', sounds.sounds, true),
      journey_variable_options: filterRelated(organization_id, 'journey_variable', 'journey', journey_variables.journey_variables, true),
      journey_variable_alias_options: filterRelated(organization_id, 'journey_variable', 'journey', journey_variables.journey_variables, false).map(journey_variable => ({ key: journey_variable.alias, title: journey_variable.title })),
      rule_field_options: filterRelated(organization_id, 'field', 'journey', fields.fields, false, f => f.type === FieldTypeEnum.Conversation && f.removable).map(field => ({ key: field.alias, title: field.title })),
      rule_contact_field_options: filterRelated(organization_id, 'field', 'journey', fields.fields, false, f => f.type === FieldTypeEnum.Contact && f.removable).map(field => ({ key: field.alias, title: field.title })),
      field_options: filterRelated(organization_id, 'field', 'journey', fields.fields.filter(f => f.type === FieldTypeEnum.Conversation), true),
      canned_answer_options: filterRelated(organization_id, 'canned_answer', 'journey', canned_answers.canned_answers, true),
      operational_hours_options: filterRelated(organization_id, 'operational_hours', 'journey', operational_hourses.operational_hourses, true),
      journey_options: journey_options,
      agent_options: filterRelated(organization_id, 'user', 'journey', users.users.filter(u => isAgent(u)), true),
      tags_options: filterRelated(organization_id, 'tag', 'journey', tags.tags, true),
      priority_options: ConversationPriorityOptions(t),
      channel_options: EnumOptions('CHANNEL', ChannelEnum, t),
      last_message_by: EnumOptions('LAST_MESSAGE_BY', ConversationLastMessageByEnum, t),
      direction_options: EnumToArray(DirectionEnum).filter(([v, k]) => [DirectionEnum.InBound, DirectionEnum.Outbound].includes(v)).map(([v, k]) => ({ key: v, title: t(`DIRECTION_${k.toUpperCase()}`) })),
      all_direction_options: EnumToArray(DirectionEnum).map(([v, k]) => ({ key: v, title: t(`DIRECTION_${k.toUpperCase()}`) })),
      exit_reason_options: EnumToArray(VoiceCallBridgeExitReasonEnum).map(([v, k]) => ({ key: v, title: t(`EXIT_REASON_${k.toUpperCase()}`) })),
      conversation_status_options: ConversationStatusOptions(t),
      time_unit_options: EnumOptions('SLA_TIME_UNIT', SlaTimeUnitEnum, t),
      language_options: EnumOptions('VOICE_LANGUAGE', VoiceLanguageEnum, t),
      outbound_voice_queue_options: filterRelated(organization_id, 'queue', 'journey', queues.queues, true, queue => queue.__outbound_voice_routes.length > 0),
      outbound_voice_queue_route_options: (props) => {
        if (!props?.properties?.outbound_voice_queue_id)
          return [];

        return (queues.dict[props.properties.outbound_voice_queue_id]?.__outbound_voice_routes || []).map(route_id => entityToOption(voice_routes.dict[route_id], 'voice_route'));
      },
    });
  }, loaders);

  const properties_conditions_rules_value = (a, b) => {
    if (b?.key)
      return {
        'conversation.organization_id': options.organization_options,
        'conversation.disposition_id': options.disposition_options,
        'conversation.sub_disposition_id': options.disposition_options,
        'conversation.thrd_disposition_id': options.disposition_options,
        'conversation.disposition': options.disposition_tree_options,
        'conversation.user_id': options.agent_options,
        'conversation.journey_id': options.journey_options,
        'conversation.queue_id': options.queue_options,
        'conversation.client_id': options.client_options,
        'conversation.skill_id': options.skill_options,
        'conversation.priority': options.priority_options,
        'conversation.status': options.conversation_status_options,
        'conversation.direction': options.direction_options,
        'conversation.main_channel': options.channel_options,
        'conversation.channels': options.channel_options,
        'conversation.tags': options.tags_options,
        'conversation.last_message_by': options.last_message_by,

        'mail_account.id': options.mail_account_options,
        'mail_message.mail_account_id': options.mail_account_options,

        'voice_call.voice_asterisk_id': options.voice_asterisk_options,
        'voice_call.voice_route_id': options.voice_route_options,
        'voice_call.queue_id': options.queue_options,

        'current_voice_call.direction': options.all_direction_options,
        'current_voice_call_bridge.user_id': options.agent_options,
        'current_voice_call_bridge.queue_id': options.queue_options,
        'current_voice_call_bridge.direction': options.all_direction_options,
        'current_voice_call_bridge.exit_reason': options.exit_reason_options,

        'first_voice_call_bridge.user_id': options.agent_options,
        'first_voice_call_bridge.queue_id': options.queue_options,
        'first_voice_call_bridge.direction': options.all_direction_options,
        'first_voice_call_bridge.exit_reason': options.exit_reason_options,

        'last_voice_call_bridge.user_id': options.agent_options,
        'last_voice_call_bridge.queue_id': options.queue_options,
        'last_voice_call_bridge.direction': options.all_direction_options,
        'last_voice_call_bridge.exit_reason': options.exit_reason_options,

        'voice_route.id': options.voice_route_options,
        'form_submission.form_website_id': options.form_website_options,
        'user.id': options.agent_options,
      }[b.key] || null;

    return null;
  };

  // error handling
  useEffect(() => {

    if (!journeyValue)
      return;

    let nodes_errors: Record<string, Array<IJourneyNodeError>> = {};
    let edge_errors: Record<string, Array<IJourneyEdgeError>> = {};
    const entry_nodes: Array<IEntryNode> = [];

    Object.entries(journeyValue.nodes).forEach(([key, journey_node]) => {
      const action = getActionProps(journey_node.action);
      const inputs: Array<IJourneyPayloadLinksKeyProperty> = [];
      const outputs: Array<IJourneyPayloadLinksKeyProperty> = [];

      if (!Object.keys(journey_node.ports).some(k => k.endsWith('_INPUT')))
        entry_nodes.push(getEntryNodes(journeyValue, journey_node));

      Object.values(journeyValue.links).forEach(l => {
        if (l.from.nodeId === journey_node.id)
          outputs.push(l);
        else if (l.to.nodeId === journey_node.id)
          inputs.push(l);
      });

      const form_values: Record<string, any> = {
        title: journey_node.title,
        description: journey_node.description
      };

      if (journey_node.properties)
        form_values.properties = journey_node.properties;

      let validation;

      try {
        validation = fromJson(action.settings_schema).validate(form_values, { abortEarly: false });
      } catch (e) {
        console.log('journey node shared validation catched', e);
      }

      const node_errors = validateJourneyNode(
        journeyValue,
        journey_node,
        inputs,
        outputs,
        validation
      ).map(e => ({ ...e, reason: t(`JOURNEY_ERROR_${e.reason}`) }));

      if (node_errors.length)
        nodes_errors[journey_node.id] = node_errors;

    });

    errorEntryNodes(flattenEntryNodes(entry_nodes)).forEach(({ journey }) => {
      journey.forEach(({ link, node, error }) => {
        if (error)
          edge_errors[link.id] = [{
            edgeId: link.id,
            reason: t('EDGE_ERROR_CHANNELS_MISMATCH')
          }];
      });
    });

    /*
    Object.entries(journeyValue.links).forEach(([key, journey_link]) => {
      const from_node = journeyValue.nodes[journey_link.from.nodeId];
      const to_node = journeyValue.nodes[journey_link.to.nodeId];

      if (!from_node || !to_node)
        return;

      const from_node_action: IJourneyActionProps = getActionProps(from_node.action);
      const to_node_action: IJourneyActionProps = getActionProps(to_node.action);

      if (!from_node_action.channels && to_node_action.channels)
        edge_errors[journey_link.id] = [{
          edgeId: journey_link.id,
          reason: t('EDGE_ERROR_FROM_NONE_CHANNEL_SPECIFIC_TO_CHANNEL_SPECIFIC')
        }];
      // else if (from_node_action.channels && !(to_node_action.channels || []).some(c => from_node_action.channels?.includes(c)))
      //   edge_errors[journey_link.id] = [{
      //     edgeId: journey_link.id,
      //     reason: t('EDGE_ERROR_CHANNEL_DONT_EXIST_IN_FROM_NODE')
      //   }];
    });
    */

    setNodes(nodes.map(node => {
      return {
        ...node,
        className: node.id === highlight ? 'highlight' : '',
        data: {
          ...node.data,
          errors: [...(nodes_errors[node.id] || [])]
        }
      };
    }));

    setEdges(edges.map(edge => {
      return {
        ...edge,
        data: {
          ...edge.data,
          errors: [...(edge_errors[edge.id] || [])]
        }
      };
    }));

    onChange([...Object.keys(nodes_errors), ...Object.keys(edge_errors)].length ? undefined : journeyValue);
  }, [journeyValue]);

  useEffect(() => {
    const _edges: ISharedJourneyDraft['payload']['links'] = {};

    edges.forEach(element => {
      if (element.sourceHandle && element.targetHandle)
        _edges[element.id] = {
          id: element.id,
          from: {
            nodeId: element.source,
            portId: element.sourceHandle
          },
          to: {
            nodeId: element.target,
            portId: element.targetHandle
          }
        };
    });

    const _nodes: ISharedJourneyDraft['payload']['nodes'] = {};

    nodes.forEach(element => {
      const node = element.data.node;

      _nodes[element.id] = {
        id: element.id,
        action: node.action,
        title: node.title,
        description: node.description,
        type: node.type,
        properties: node.properties,
        position: element.position,
        ports: node.ports
      };
    });

    if (
      (JSON.stringify(journeyValue.links) !== JSON.stringify(_edges))
      || (JSON.stringify(journeyValue.nodes) !== JSON.stringify(_nodes))
    ) {
      setJourneyValue({
        ...journeyValue,
        links: _edges,
        nodes: _nodes
      });
    }
  }, [edges, nodes, journeyValue, setJourneyValue]);

  useEffect(() => {
    const _nodes: ISharedJourneyDraft['payload']['nodes'] = {};

    nodes.forEach(element => {
      const node = element.data.node;

      _nodes[element.id] = {
        id: element.id,
        action: node.action,
        title: node.title,
        description: node.description,
        type: node.type,
        properties: node.properties,
        position: element.position,
        ports: node.ports
      };
    });

    if (JSON.stringify(journeyValue.nodes) !== JSON.stringify(_nodes)) {
      setJourneyValue({
        ...journeyValue,
        nodes: _nodes
      });
    }
  }, [nodes, journeyValue, setJourneyValue]);

  const buildJourney = useCallback((value: ISharedJourneyDraft['payload']) => {

    if (!options || loaded)
      return;

    const nodes = Object.values(value.nodes).map((node, index) => createNode(value, node, t, store, options));
    const edges = Object.values(value.links).map((link, index) => createConnector(link));

    setLoaded(true);
    setNodes(nodes);
    setEdges(edges);

  }, [options, setEdges, setNodes, store, t]);

  useEffect(() => {
    if (options)
      buildJourney(value);
  }, [options, buildJourney, setJourneyValue, value]);

  const onConnect = useCallback((params) => {
    if (params.source === params.target)
      return;

    setEdges([...edges, createConnector({
      id: `link_${Date.now()}`,
      from: {
        nodeId: params.source,
        portId: params.sourceHandle
      },
      to: {
        nodeId: params.target,
        portId: params.targetHandle
      }
    })]);
  }, [edges, setEdges]);

  const addNode = useCallback((node) => {
    const element = createNode(value, node, t, store, options);
    const { x, y, zoom } = viewport;
    const { width, height } = storeApi.getState();

    setNodes([
      ...nodes,
      {
        ...element,
        position: { x: (width / 2 - x / zoom) - (270 * zoom), y: (height / 2 - y / zoom) - (70 * zoom) }
      }
    ]);

    //instance?.setCenter(centerX, centerY, { zoom });

  }, [nodes, options, setNodes, store, storeApi, t, value, viewport]);

  if (!options || !value)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <div className={'journey-nodes-container'} id='journey-nodes-container'>
      <div id='journey-nodes-container-content'>
        {!locked && <JourneysSideList
          reset={() => {
            buildJourney({ scale: 1, offset: { x: 0, y: 0 }, nodes: {}, links: {} });
          }}
          reload={() => {
            if (!journey)
              return;

            buildJourney(journey.payload);

          }}
          onAdd={addNode.bind({
            nodes
          })}
        />}
        <ReactFlow
          style={{ width:'auto', height: 'auto', flex: 1 }}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.SmoothStep}
          nodesDraggable={!locked}
          nodesConnectable={!locked}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          snapToGrid={true}
          defaultPosition={[value.offset.x, value.offset.y]}
          defaultZoom={value.scale}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          zoomOnDoubleClick={false}
          onNodeDoubleClick={(ev, d) => {

            if (!isDraft)
              return;

            const node = d.data.node;
            const initialValues: Partial<IJourneyPayloadNodesKeyProperty> = {
              title: node.title,
              description: node.description,
            };

            if (node.properties)
              initialValues.properties = clone(node.properties);

            if (node.action === JourneyActionEnum.When && !initialValues.properties)
              initialValues.properties = {};

            const action: IJourneyActionProps = getActionProps(node.action);
            const modal = FormModal(t, {
              width: [
                JourneyActionEnum.SendMail,
                JourneyActionEnum.ForwardMail,
                JourneyActionEnum.Rule,
                JourneyActionEnum.RuleContact,
                JourneyActionEnum.RestRequest,
                JourneyActionEnum.FetchContactData,
                JourneyActionEnum.EditConversation
              ].includes(node.action) ? 1000 : 600,
              content: (
                <div className={'node-modal'}>
                  <span className={'title'}>
                    {getActionIconText(action, t, node)}
                  </span>
                  <span className={'body'}>
                    <AtlasForm
                      dispatch={dispatch}
                      formLayout={{
                        id: node.id,
                        ui_layout: FormLayoutTypes.HORIZONTAL,
                        ...(action.settings_form || {})
                      }}
                      form={fromJson(action.settings_schema)}
                      initialValues={initialValues}
                      additionalParams={{
                        ...action.additionalParams,
                        properties: { hideLabel: true, noPadding: true },
                        // properties_queue_id: {
                        //   onModalAdd: {
                        //     key: 'journey-queue-add',
                        //     path: '@Components/page-views/queue',
                        //     component: 'QueueFormAddView',
                        //   }
                        // },
                        properties_conditions_rules_channel: { hideLabel: true },
                        properties_conditions_rules_queue_id: { hideLabel: true },
                        properties_conditions_rules_value: (a, b) => ({ ...action.additionalParams?.properties_conditions_rules_value, postFixText: b.key === '_queue.longest_waiting' ? t('SECONDS') : undefined }),
                        properties_signature_id: {
                          onModalAdd: {
                            key: 'journey-signature-add',
                            path: '@Components/page-views/signature',
                            component: 'SignatureFormAddView',
                          }
                        },
                        properties_set_value: (a, b) => ({
                          ui_type: b.property === 'disposition_note' ? FormElementTypes.TEXT : b.property === 'disposition' ? FormElementTypes.TREE : FormElementTypes.SELECT
                        }),
                        properties_conditions_rules: {
                          ui_layout: FormLayoutTypes.VERTICAL
                        },
                        properties_disposition_id: {
                          onModalAdd: {
                            key: 'journey-disposition-add',
                            path: '@Components/page-views/disposition',
                            component: 'DispositionFormAddView',
                          },
                          ui_type: FormElementTypes.TREE
                        },
                        properties_canned_answer_id: {
                          onModalAdd: {
                            key: 'journey-canned_answer-add',
                            path: '@Components/page-views/canned_answer',
                            component: 'CannedAnswerFormAddView',
                          }
                        },
                        properties_canned_answer_id2: {
                          onModalAdd: {
                            key: 'journey-canned_answer-add2',
                            path: '@Components/page-views/canned_answer',
                            component: 'CannedAnswerFormAddView',
                          }
                        },
                        properties_canned_answer_id3: {
                          onModalAdd: {
                            key: 'journey-canned_answer-add3',
                            path: '@Components/page-views/canned_answer',
                            component: 'CannedAnswerFormAddView',
                          }
                        },
                        properties_canned_answer_id4: {
                          onModalAdd: {
                            key: 'journey-canned_answer-add4',
                            path: '@Components/page-views/canned_answer',
                            component: 'CannedAnswerFormAddView',
                          }
                        },
                        properties_field_id: {
                          onModalAdd: {
                            key: 'journey-field-add',
                            path: '@Components/page-views/field',
                            component: 'FieldFormAddView',
                          }
                        },
                        properties_asterisk_sound_id: {
                          onModalAdd: {
                            key: 'journey-sound-add',
                            path: '@Components/page-views/sound',
                            component: 'SoundFormAddView',
                          }
                        },
                        properties_voice_voicemail_id: {
                          onModalAdd: {
                            key: 'journey-voice_voicemail-add',
                            path: '@Components/page-views/voice_voicemail',
                            component: 'VoiceVoicemailFormAddView',
                          }
                        },
                        properties_journey_variable_id: {
                          onModalAdd: {
                            key: 'journey-journey_variable-add',
                            path: '@Components/page-views/journey_variable',
                            component: 'JourneyVariableFormAddView',
                          }
                        },
                        properties_operational_hours_id: {
                          onModalAdd: {
                            key: 'journey-operational_hours-add',
                            path: '@Components/page-views/operational_hours',
                            component: 'OperationalHoursFormAddView',
                          }
                        }
                      }}
                      previews={{
                        properties_asterisk_sound_id: (it: any) => it?.properties?.asterisk_sound_id ? <AudioPlayer key={it.properties.asterisk_sound_id} t={t} stream={() => streamSoundFilename(it.properties.asterisk_sound_id)} size='s' /> : <></>
                      }}
                      options={{
                        properties_signature_id: options.signature_options,
                        properties_user_id: options.agent_options,
                        properties_queue_id: options.queue_options,
                        properties_client_id: options.client_options,
                        properties_skill_id: options.skill_options,
                        properties_outbound_voice_queue_id: options.outbound_voice_queue_options,
                        properties_outbound_voice_queue_route_id: options.outbound_voice_queue_route_options,
                        properties_disposition_id: options.disposition_options,
                        properties_disposition: options.disposition_tree_options,
                        properties_mail_account_id: options.mail_account_options,
                        properties_voice_route_id: options.voice_route_options,
                        properties_voice_asterisk_id: options.voice_asterisk_options,
                        properties_canned_answer_id: options.canned_answer_options,
                        properties_canned_answer_id2: options.canned_answer_options,
                        properties_canned_answer_id3: options.canned_answer_options,
                        properties_canned_answer_id4: options.canned_answer_options,
                        properties_operational_hours_id: options.operational_hours_options,
                        properties_journey_id: options.journey_options,
                        properties_since_since_unit: options.time_unit_options,
                        properties_asterisk_sound_id: options.asterisk_sound_options,
                        properties_journey_variable_id: options.journey_variable_options,
                        properties_journey_variables_journey_variable_id: options.journey_variable_options,
                        properties_field_id: options.field_options,
                        properties_fields_field_id: options.field_options,
                        properties_voice_voicemail_id: options.voice_voicemail_options,
                        properties_body_variable_alias: options.journey_variable_alias_options,
                        properties_headers_variable_alias: options.journey_variable_alias_options,
                        properties_query_params_variable_alias: options.journey_variable_alias_options,
                        properties_language: options.language_options,
                        properties_conditions_rules_channel: options.channel_options,
                        properties_conditions_rules_queue_id: options.queue_options,
                        properties_set_value: (a, b) => {
                          if (b?.property)
                            return {
                              'disposition_id': options.disposition_options,
                              'disposition': options.disposition_tree_options,
                              'client_id': options.client_options,
                              'skill_id': options.skill_options,
                              'priority': options.priority_options,
                              'status': options.conversation_status_options.filter(o => o.key !== ConversationStatusEnum.Closed),
                              'add_tag': options.tags_options,
                              'remove_tag': options.tags_options
                            }[b.property] || null;

                          return null;
                        },
                        properties_conditions_rules_data_key: (a, b) => b.key === 'contact.data' ? options.rule_contact_field_options : options.rule_field_options, //store.fields.fields.filter(f => f.type === FieldTypeEnum.Conversation && f.removable).map(f => ({ key: f.alias, title: t(f.title) })),
                        properties_query_params_data_key: (a, b) => b.value === 'contact.data' ? options.rule_contact_field_options : options.rule_field_options, //store.fields.fields.filter(f => f.type === FieldTypeEnum.Conversation && f.removable).map(f => ({ key: f.alias, title: t(f.title) })),
                        properties_headers_data_key: (a, b) => b.value === 'contact.data' ? options.rule_contact_field_options : options.rule_field_options, //store.fields.fields.filter(f => f.type === FieldTypeEnum.Conversation && f.removable).map(f => ({ key: f.alias, title: t(f.title) })),
                        properties_body_data_key: (a, b) => b.value === 'contact.data' ? options.rule_contact_field_options : options.rule_field_options, //store.fields.fields.filter(f => f.type === FieldTypeEnum.Conversation && f.removable).map(f => ({ key: f.alias, title: t(f.title) })),
                        properties_condition_data_changed: options.rule_field_options,
                        properties_contact_condition_data_changed: options.rule_contact_field_options,
                        properties_conditions_rules_variable_alias: options.journey_variable_alias_options,
                        properties_conditions_rules_value,
                        properties_conditions_rules_value_arr: properties_conditions_rules_value
                      }}
                      antForm={antForm}
                      onFinish={(values) => {
                        const data = {
                          ...values,
                          ports: action.getPorts({
                            ...values,
                            ports: node.ports
                          })
                        };

                        setNodes(nodes.map(el => {
                          if (node.id === el.id)
                            return { ...el, ...{ data: { ...el.data, node: { ...el.data.node, ...data } } } };

                          return el;
                        }));

                        setTimeout(() => {
                          updateNodeInternals(node.id);
                        }, 100);
                        modal.destroy();
                      }}
                      onCancel={() => modal.destroy()}
                    />
                  </span>
                </div>
              )
            });
          }}
          connectionLineStyle={connectionLineStyle}
          snapGrid={snapGrid}
          onlyRenderVisibleElements={false}
          deleteKeyCode={'Delete'}
        >
          <MiniMap nodeBorderRadius={2} />
          <Controls showInteractive={false} />
          <Background color="#aaa" gap={20} />
        </ReactFlow>
      </div>
    </div>
  );
};
