import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  PointsSystemEditForm,
  PointsSystemEditInitialValues,
  PointsSystemComponents,
  PointsSystemAdditionalParams,
  PointsSystemFormOptions,
  onPointsSystemFormSaved,
  IPointsSystemFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedPointsSystem, ISharedPatchPointsSystem, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchPointsSystem, patchPointsSystem, getPointsSystemUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useQueues, useSkills } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedPointsSystem> {}

const PointsSystemFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const queues = useQueues();
  const skills = useSkills();

  useEffect(() => {
    fetchPointsSystem(id).then(points_system => setValue(points_system));
  }, [id, setValue]);

  const param_props: IPointsSystemFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    queues: queues.queues,
    skills: skills.skills
  };
  const formLayout: IForm | undefined = useMemo(() => value && PointsSystemEditForm(value, param_props, t), [value, organizations, queues, skills, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && PointsSystemFormOptions(value, param_props, t), [value, organizations, queues, skills, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchPointsSystem, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchPointsSystem) => value && save(() => patchPointsSystem(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onPointsSystemFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !queues.loaded || !skills.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.PointsSystem, value)}
    >
      <AtlasForm<ISharedPatchPointsSystem>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={PointsSystemComponents}
        additionalParams={PointsSystemAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={PointsSystemEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('POINTS_SYSTEM')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const PointsSystemFormEditView = React.memo(withEdit(PointsSystemFormEdit, getPointsSystemUpdateSchema));
