import { IPreviewPointsSystem } from 'atlas-shared';
import { IPointsSystemStore, PointsSystemActions, TPointsSystemActionTypes } from '@Store';

export const PointsSystemReducer = (state: IPointsSystemStore = {
  loaded: false,
  loading: false,
  points_systems: [],
  dict: {}
}, action: TPointsSystemActionTypes): IPointsSystemStore => {
  switch (action.type) {
  case PointsSystemActions.LOADING:
    return { ...state, loading: true };
  case PointsSystemActions.SET_STATE:
    const dict: IPointsSystemStore['dict'] = {};

    action.payload.forEach((points_system: IPreviewPointsSystem) => dict[points_system.id] = points_system);

    return { ...state, loaded: true, loading: false, points_systems: action.payload, dict };
  case PointsSystemActions.ADD_STATE:
    return { ...state, points_systems: [...state.points_systems, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case PointsSystemActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, points_systems: state.points_systems.filter((item: IPreviewPointsSystem) => item.id !== action.payload), dict };
  }
  case PointsSystemActions.UPDATE_STATE:
    const points_systems = [...state.points_systems];
    const index = state.points_systems.findIndex((points_system: IPreviewPointsSystem) => points_system.id === action.payload.id);

    if (!~index)
      points_systems.push(action.payload);
    else
      points_systems[index] = action.payload;

    return { ...state, points_systems, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
