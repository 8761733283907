import { GlobalActionTypes, IGlobalStore } from '@Store';

export const GlobalReducer = (state: IGlobalStore = {
  loaders: 0,
  theme: 'light',
  globalSearch: '',
  hideStats: true,
  hideDashboardLeft: false,
  focused_conversation: null,
  handling_conversation: null,
  call: null,
  calls: null,
  voice_queue_route_options: [],
  voice_queue_route_selected: null,
  voice_volume: 1,
  voice_mic: true,
  reset_password: null,
  display_audio_settings: false,
  devices: {
    ringtone: 'default',
    speaker: 'default',
    microphone: 'default',
  },
  devices_available: {
    ringtone: false,
    speaker: false,
    microphone: false,
  },
  reset_conversation_list: null,
  inactive: false,
  socket_disconnected: false,
  is_print: new URLSearchParams(window.location.search).get('print') === '1',
  is_fullscreen: false,
  popup_modals: [],
  display_changelog: false,
  docs_preview: null,
  switching_profile: false,
  key_pressed: undefined
}, action: {
  type: string;
  payload: any;
}): IGlobalStore => {
  switch (action.type) {
  case GlobalActionTypes.ADD_STATE:
    return {
      ...state,
      [action.payload.name]:{
        name: action.payload.name,
        id: action.payload.id,
        values: action.payload.values
      }
    };
  case GlobalActionTypes.REMOVE_STATE:
    const newState = { ...state };

    delete newState[action.payload.name];
    return newState;

  case GlobalActionTypes.ADD_LOADER:
    return {
      ...state,
      loaders: state.loaders + 1
    };

  case GlobalActionTypes.REDUCE_LOADER:
    return {
      ...state,
      loaders: Math.max(state.loaders - 1, 0)
    };

  case GlobalActionTypes.HIDE_STATS:
    return {
      ...state,
      hideStats: action.payload
    };

  case GlobalActionTypes.HIDE_DASHBOARD_LEFT:
    return {
      ...state,
      hideDashboardLeft: action.payload
    };

  case GlobalActionTypes.RESET_PASSWORD:
    return {
      ...state,
      reset_password: action.payload
    };

  case GlobalActionTypes.DISPLAY_AUDIO_SETTINGS:
    return {
      ...state,
      display_audio_settings: action.payload
    };

  case GlobalActionTypes.SET_DEVICES:
    const devices = {
      ...(state.devices || {}),
      ...action.payload
    };

    localStorage.setItem('devices', JSON.stringify({
      ringtone: devices.ringtone,
      speaker: devices.speaker,
      microphone: devices.microphone
    }));
    return {
      ...state,
      devices
    };

  case GlobalActionTypes.SET_DEVICES_AVAILABLE:
    return {
      ...state,
      devices_available: {
        ...(state.devices_available || {}),
        ...action.payload
      }
    };

  case GlobalActionTypes.SET_GLOBAL_CALL:
    return {
      ...state,
      call: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_CALLS:
    return {
      ...state,
      calls: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_VOICE_VOLUME:
    return {
      ...state,
      voice_volume: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_VOICE_MIC:
    return {
      ...state,
      voice_mic: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_VOICE_QUEUE_ROUTE_OPTIONS:
    return {
      ...state,
      voice_queue_route_options: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_VOICE_QUEUE_ROUTE_SELECTED:
    return {
      ...state,
      voice_queue_route_selected: action.payload
    };

  case GlobalActionTypes.SET_FOCUSED_CONVERSATION:
    return {
      ...state,
      focused_conversation: action.payload
    };

  case GlobalActionTypes.SET_HANDLING_CONVERSATION:
    return {
      ...state,
      handling_conversation: action.payload
    };

  case GlobalActionTypes.SET_THEME:

    const theme = action.payload;

    localStorage.setItem('atlas-theme', theme as string);
    document.body.classList.remove('light');
    document.body.classList.remove('dark');
    document.body.classList.add(theme);

    return {
      ...state,
      theme
    };

  case GlobalActionTypes.SET_GLOBAL_SEARCH:
    return {
      ...state,
      globalSearch: action.payload
    };

  case GlobalActionTypes.SET_RESET_CONVERSATION_LIST:
    return {
      ...state,
      reset_conversation_list: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_INACTIVE:
    return {
      ...state,
      inactive: action.payload
    };

  case GlobalActionTypes.SET_SOCKET_DISCONNECTED:
    return {
      ...state,
      socket_disconnected: action.payload
    };

  case GlobalActionTypes.SET_GLOBAL_FULLSCREEN:
    return {
      ...state,
      is_fullscreen: action.payload
    };

  case GlobalActionTypes.ADD_GLOBAL_POPUP_MODAL:
    return {
      ...state,
      popup_modals: [...state.popup_modals, action.payload]
    };

  case GlobalActionTypes.REMOVE_GLOBAL_POPUP_MODAL:
    return {
      ...state,
      popup_modals: state.popup_modals.filter(popup_modal => popup_modal.key !== action.payload)
    };

  case GlobalActionTypes.DISPLAY_CHANGELOG:
    return {
      ...state,
      display_changelog: action.payload
    };

  case GlobalActionTypes.DOCS_PREVIEW:
    return {
      ...state,
      docs_preview: action.payload
    };

  case GlobalActionTypes.SWITCHING_PROFILE:
    return {
      ...state,
      switching_profile: action.payload
    };

  case GlobalActionTypes.KEY_PRESSED:
    return {
      ...state,
      key_pressed: action.payload
    };

  default:
    return state;
  }

  // LocalStorage.saveState(new_state);
};
