import { JourneyTriggerActions } from '@Store';
import { IPreviewJourneyTrigger } from 'atlas-shared';
import { fetchJourneyTriggers } from '@Api';

export const actionFetchJourneyTriggers = () => {
  return async (dispatch) => {
    dispatch(actionLoadingJourneyTriggers());
    const journey_triggers = await fetchJourneyTriggers();

    dispatch(actionSetJourneyTriggers(journey_triggers));
    return journey_triggers;
  };
};

export const actionLoadingJourneyTriggers = () => ({
  type: JourneyTriggerActions.LOADING,
  payload: []
});

export const actionSetJourneyTriggers = (payload: Array<IPreviewJourneyTrigger>) => ({
  type: JourneyTriggerActions.SET_STATE,
  payload: payload
});

export const actionAddJourneyTrigger = (payload: IPreviewJourneyTrigger) => ({
  type: JourneyTriggerActions.ADD_STATE,
  payload: payload
});

export const actionUpdateJourneyTrigger = (payload: IPreviewJourneyTrigger) => ({
  type: JourneyTriggerActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveJourneyTrigger = (id: IPreviewJourneyTrigger['id']) => ({
  type: JourneyTriggerActions.REMOVE_STATE,
  payload: id
});
