import React, { PropsWithChildren, useEffect } from 'react';
import { actionKeyPressed, IGlobalKeyPressed, useAppDispatch } from '@Store';

export const KeyboardKeydown = React.memo(({ children }: PropsWithChildren<{}>) => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      //console.log('keydown', (e.srcElement as HTMLElement)?.className, (e.srcElement as HTMLElement)?.tagName, (e.srcElement as HTMLElement)?.contentEditable, e.code, e);
      const elm: any = e.srcElement as unknown as HTMLElement;

      dispatch(actionKeyPressed({
        code: e.code,
        keyCode: e.keyCode,
        which: e.which,
        contentEditable: ['INPUT', 'TEXTAREA'].includes(elm.tagName) || elm.className.includes('ql-editor'),
        className: elm.className,
        tagName: elm.tagName
      } as IGlobalKeyPressed));
    });

  }, []);

  return <div>
    {children}
  </div>;
});
