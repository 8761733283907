import { IPreviewJourneyTrigger } from 'atlas-shared';
import { IJourneyTriggerStore, JourneyTriggerActions, TJourneyTriggerActionTypes } from '@Store';

export const JourneyTriggerReducer = (state: IJourneyTriggerStore = {
  loaded: false,
  loading: false,
  journey_triggers: [],
  dict: {}
}, action: TJourneyTriggerActionTypes): IJourneyTriggerStore => {
  switch (action.type) {
  case JourneyTriggerActions.LOADING:
    return { ...state, loading: true };
  case JourneyTriggerActions.SET_STATE:
    const dict: IJourneyTriggerStore['dict'] = {};

    action.payload.forEach((journey_trigger: IPreviewJourneyTrigger) => dict[journey_trigger.id] = journey_trigger);

    return { ...state, loaded: true, loading: false, journey_triggers: action.payload, dict };
  case JourneyTriggerActions.ADD_STATE:
    return { ...state, journey_triggers: [...state.journey_triggers, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case JourneyTriggerActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, journey_triggers: state.journey_triggers.filter((item: IPreviewJourneyTrigger) => item.id !== action.payload), dict };
  }
  case JourneyTriggerActions.UPDATE_STATE:
    const journey_triggers = [...state.journey_triggers];
    const index = state.journey_triggers.findIndex((journey_trigger: IPreviewJourneyTrigger) => journey_trigger.id === action.payload.id);

    if (!~index)
      journey_triggers.push(action.payload);
    else
      journey_triggers[index] = action.payload;

    return { ...state, journey_triggers, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
