import React from 'react';
import { ISharedVoiceRecordingTranscriptTranscriptSentencesWordProperty } from 'atlas-shared/dist';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
  word: ISharedVoiceRecordingTranscriptTranscriptSentencesWordProperty;
  playing: boolean;
}

export const ConversationMessageVoiceCallVoiceRecordingTranscriptSentenceWord = React.memo(({ t, word, playing }: IProps) => {

  return <span
    className={`word${word.confidence < .55 ? ' unconfident' : ''}${playing ? ' playing' : ''}`}
    data-confidence={Math.round(word.confidence * 100)}
    title={`${t('CONFIDENCE')}: ${Math.round(word.confidence * 100)}%`}
  >
    {word.word}
  </span>;

});
