import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './audio-player.scss';
import { DownloadIcon, GlobeAddIcon, GlobeIcon, MicIcon, PauseIcon, PlayIcon } from '@Assets';
import { preventDefault, secondsToTime, timeDiff } from '@Utils';
import { IPreviewUser, Nullable, IPreviewVoiceRecording, Undefinable, ISharedVoiceRecordingTranscript } from 'atlas-shared';
import { Avatar, Icon, Timer } from '@Components';
import { TFunction } from 'i18next';
import { IUserStore } from '@Store';

export enum AudioPlayerTheme {
  Playback = 'playback',
  RecordingRecording = 'recording-recording',
  RecordingPlayable = 'recording-playable',
  VoicemailRecording = 'voicemail-recording',
  VoicemailPlayable = 'voicemail-playable'
}

enum CurrentStateEnum {
  Initial = 1,
  Loading = 2,
  Playing = 3,
  Paused = 4,
  Ended = 5
}

interface IProps {
  t: TFunction;
  users?: IUserStore;
  canPlay?: boolean;
  src?: string;
  start_time?: IPreviewVoiceRecording['created_at'];
  end_time?: IPreviewVoiceRecording['stopped_at'];
  download?: Function;
  theme?: AudioPlayerTheme;
  user_id?: Nullable<IPreviewUser['id']>;
  size?: 'm' | 's';
  stream?: Function;
  file_title?: string;
  autoPlay?: boolean;
  transcript?: {
    id: IPreviewVoiceRecording['voice_recording_transcript_id'];
    get?: ISharedVoiceRecordingTranscript['id'];
    set: (id?: ISharedVoiceRecordingTranscript['id']) => void;
  };
  generate_transcript?: () => void;
  setCurrentTime?: (seconds: number) => void;
}

export const AudioPlayer = React.memo(({
  t,
  user_id,
  src: _src,
  file_title,
  users,
  start_time,
  end_time,
  download,
  stream,
  transcript,
  setCurrentTime,
  autoPlay = true,
  theme = AudioPlayerTheme.Playback,
  canPlay = true,
  size = 'm',
  generate_transcript
}: IProps) => {
  const refPlayer = useRef<HTMLAudioElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState<number>();
  const [title, setTitle] = useState<string>();
  // const [currentTime, setCurrentTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [state, setState] = useState<CurrentStateEnum>(CurrentStateEnum.Initial);
  const [error, setError] = useState<string>();
  const [src, setSrc] = useState<Undefinable<string>>(_src);
  const [hoverMarker, setHoverMarker] = useState<{
    second: number;
    left: number;
  }>({
    second: 0,
    left: 0
  });

  useEffect(() => setSrc(_src), [_src]);

  useEffect(() => {
    switch (theme) {
    case AudioPlayerTheme.RecordingPlayable:
      setTitle(t('CALL_RECORDING'));
      break;
    case AudioPlayerTheme.RecordingRecording:
      setTitle(t('RECORDING_CONVERSATION'));
      break;
    case AudioPlayerTheme.VoicemailPlayable:
      setTitle(t('VOICEMAIL'));
      break;
    case AudioPlayerTheme.VoicemailRecording:
      setTitle(t('RECORDING_VOICEMAIL'));
      break;
    }
  }, [theme]);

  useEffect(() => {
    if (start_time && end_time)
      setDuration(timeDiff(start_time, end_time));
  }, [start_time, end_time]);

  const onTimeUpdate = useCallback(() => {
    if (!refPlayer.current)
      return;

    if (duration !== refPlayer.current.duration)
      setDuration(refPlayer.current.duration);

    setProgress(refPlayer.current.currentTime / refPlayer.current.duration * 100);

    if (setCurrentTime)
      setCurrentTime(refPlayer.current.currentTime);

  }, [duration]);

  const onPlaying = useCallback(() => {
    setState(CurrentStateEnum.Playing);
  }, []);

  const onPause = useCallback(() => {
    setState(CurrentStateEnum.Paused);
  }, []);

  const onEnded = useCallback(() => {
    setState(CurrentStateEnum.Ended);
  }, []);

  const onLoad = useCallback(() => {
    if (refPlayer?.current)
      setDuration(refPlayer.current.duration);
  }, []);

  const onError = useCallback((e) => {
    setError(t('AUDIO_NOT_PLAYABLE'));
  }, []);

  useEffect(() => {
    if (src && state === CurrentStateEnum.Initial)
      setState(CurrentStateEnum.Loading);
  }, [src, state]);

  const onSetTime = useCallback((e) => {
    if (isPLaying && duration && refContainer.current && refPlayer.current)
      refPlayer.current.currentTime = (e.clientX - refContainer.current.getBoundingClientRect().left) * (duration / refContainer.current.offsetWidth);
  }, [duration]);

  const user = useMemo(() => {
    return user_id && users ? <Avatar size={16} user={users.dict[user_id]} /> : undefined;
  }, [user_id]);

  const isPLaying = useMemo(() => state === CurrentStateEnum.Playing, [state]);

  const onHover = useCallback((e) => {
    if (!refContainer.current)
      return;

    const rect = refContainer.current.getBoundingClientRect();

    setHoverMarker({
      second: duration ? (e.clientX - refContainer.current.getBoundingClientRect().left) * (duration / refContainer.current.offsetWidth) : 0,
      left: e.clientX - rect.left
    });
  }, [duration]);

  return <div className={`audio-player-wrapper size-${size}`} title={file_title} onClick={() => {
    if (stream && !src)
      stream().then(blob => setSrc(blob));
  }}>
    {canPlay && <div
      className={`audio-player theme-${theme}${isPLaying ? ' playing' : ''}${!end_time && !stream ? ' recording' : ''}${error ? ' error' : ''}`}
      ref={refContainer}
      onClick={onSetTime}
      onMouseMoveCapture={onHover}
      onMouseOut={() => {
        setHoverMarker({
          second: 0,
          left: 0
        });
      }}
    >
      {!!hoverMarker?.second && <span className='hover-marker' style={{ left: hoverMarker.left }}>{secondsToTime(hoverMarker.second)}</span>}
      <div className='progress'>
        <div className='inner' style={{ width: `${progress}%` }}></div>
      </div>
      <audio
        ref={refPlayer}
        autoPlay={autoPlay}
        src={src}
        onTimeUpdate={onTimeUpdate}
        onPlaying={onPlaying}
        onPause={onPause}
        onEnded={onEnded}
        onCanPlay={onLoad}
        onError={onError}
      ></audio>
      {[AudioPlayerTheme.RecordingRecording, AudioPlayerTheme.VoicemailRecording].includes(theme) && <div className='left-button'><MicIcon /></div>}
      {!isPLaying && [AudioPlayerTheme.RecordingPlayable, AudioPlayerTheme.VoicemailPlayable, AudioPlayerTheme.Playback].includes(theme) && <div onClick={state === CurrentStateEnum.Initial ? () => {
      } : preventDefault(() => refPlayer.current?.play().catch(onError))} className={'left-button left-button-play'}><PlayIcon/></div>}
      {isPLaying && <div onClick={preventDefault(() => refPlayer.current?.pause())} className={'left-button left-button-pause'}><PauseIcon/></div>}
      <div className='title'>{error || title}</div>
      <div className='duration'>{duration ? secondsToTime(duration) : start_time && <Timer base={start_time} format="HH:mm:ss" default_value='00:00' ignoreZeros={true} color={false} />}</div>
      {user && <div className='right-button user-button'>{user}</div>}
    </div>}
    {download && <div className='download-button' onClick={preventDefault(() => download())}>
      <Icon icon={DownloadIcon} tooltip={{ title: t('DOWNLOAD_RECORDING') }} />
    </div>}
    {transcript?.id && <div className='transcript-button' onClick={preventDefault(() => transcript?.id && transcript.set(transcript.get ? undefined : transcript.id))}>
      <Icon icon={GlobeIcon} tooltip={{ title: t('DISPLAY_TRANSCRIPT') }} />
    </div>}
    {generate_transcript && <div className='generate-transcript-button'>
      <Icon icon={GlobeAddIcon} tooltip={{ title: t('GENERATE_TRANSCRIPT') }} onClick={preventDefault(generate_transcript)} />
    </div>}
  </div>;
});
