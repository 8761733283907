import { IPreviewPointsSystem } from 'atlas-shared';

export enum PointsSystemActions {
  SET_STATE = 'POINTS_SYSTEM_SET_STATE',
  ADD_STATE = 'POINTS_SYSTEM_ADD_STATE',
  REMOVE_STATE = 'POINTS_SYSTEM_REMOVE_STATE',
  GET_STATE = 'POINTS_SYSTEM_GET_STATE',
  UPDATE_STATE = 'POINTS_SYSTEM_UPDATE_STATE',
  LOADING = 'POINTS_SYSTEM_LOADING',
}

export interface IPointsSystemLoading {
  type: PointsSystemActions.LOADING;
}

export interface IPointsSystemSetAction {
  type: PointsSystemActions.SET_STATE;
  payload: Array<IPreviewPointsSystem>;
}

export interface IPointsSystemUpdateAction {
  type: PointsSystemActions.UPDATE_STATE;
  payload: IPreviewPointsSystem;
}

export interface IPointsSystemAddAction {
  type: PointsSystemActions.ADD_STATE;
  payload: IPreviewPointsSystem;
}

export interface IPointsSystemRemoveAction {
  type: PointsSystemActions.REMOVE_STATE;
  payload: IPreviewPointsSystem['id'];
}

export interface IPointsSystemStore {
  loaded: boolean;
  loading: boolean;
  points_systems: Array<IPreviewPointsSystem>;
  dict: Record<IPreviewPointsSystem['id'], IPreviewPointsSystem>;
}

export type TPointsSystemActionTypes = IPointsSystemSetAction | IPointsSystemUpdateAction | IPointsSystemAddAction | IPointsSystemRemoveAction | IPointsSystemLoading;
