import { useTranslation } from 'react-i18next';
import { SearchNoResult } from '@Components';
import './header.search.results.scss';
import React from 'react';
import { IElasticPreviewConversation, IElasticPreviewContact, IElasticSearchResult, Nullable, SearchSectionEnum } from 'atlas-shared';
import { HeaderSearchResultsConversation } from './header.search.results.conversation';
import { NavigateFunction } from 'react-router';
import { HeaderSearchResultsContacts } from './header.search.results.contacts';

interface IProps {
  conversations: IElasticSearchResult<IElasticPreviewConversation>;
  contacts: IElasticSearchResult<IElasticPreviewContact>;
  keyword: string;
  navigate: NavigateFunction;
  section: Nullable<SearchSectionEnum>;
}
export const HeaderSearchResults = React.memo(({ conversations, contacts, keyword, navigate, section }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={'search-results-wrapper'}>
      <div className={'search-section-header'} >
        <div className={'search-section-title'} >
          {t('SEARCH_RESULTS')}
        </div>
      </div>
      {!(conversations.items.length + contacts.items.length) && <SearchNoResult section={section} t={t} keyword={keyword} style={{ marginBottom: 20 }} />}
      <ul className={'search-result-list'} >
        {
          conversations.items.map(conversation => <HeaderSearchResultsConversation keyword={keyword} item={conversation} navigate={navigate} />)
        }
        <HeaderSearchResultsContacts contacts={contacts} navigate={navigate} />
      </ul>
    </div>
  );
});
