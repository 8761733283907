import { useTranslation } from 'react-i18next';
import { useAuth } from '@Hooks';
import { useNavigate } from 'react-router-dom';
import './header.search.results.scss';
import { clearRecentlyViewedConversation, deleteRecentlyViewedConversation } from '@Api';
import { ellipsis, preventDefault, sortByProperty } from '@Utils';
import React from 'react';
import { IPreviewRecentlyViewedConversation } from 'atlas-shared';
import { ChannelIcon, Icon } from '@Components';
import { XIcon } from '@Assets';
import { IRecentlyViewedConversationStore } from '@Store';

interface IProps {
  setInputFocus: Function;
  views: IRecentlyViewedConversationStore;
}

export const HeaderSearchRecentlyViewed = React.memo(({ views, setInputFocus } : IProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const onDelete = (view: IPreviewRecentlyViewedConversation) => deleteRecentlyViewedConversation(view.id);
  const onClear = () => clearRecentlyViewedConversation(auth.user.id, auth.user_status.agentprofile_id || 0);

  if (!views.recently_viewed_conversations.length)
    return <></>;

  return (
    <>
      <div className={'search-section-header'} >
        <div className={'search-section-title'} >
          {t('RECENTLY_VIEWED')}
        </div>
        <div className={'s-clear-btn'} onClick={onClear}>
          {t('CLEAR')}
        </div>
      </div>
      <ul className={'recent-viewed-list'} >
        {
          sortByProperty(views.recently_viewed_conversations, 'id').slice(0, 5).map((view) => {
            if (!view.conversation)
              return <React.Fragment key={view.id}></React.Fragment>;

            return (
              <li key={view.id} onClick={() => navigate(`/dashboard/conversation/${view.conversation.organization_id}/${view.conversation_id}`)}>
                <div className={'channel-wrp'} >
                  {view.conversation?.channels?.[0] && <ChannelIcon channel={view.conversation?.channels?.[0]} size={14} padding={7}/>}
                </div>
                <div className={'result-text'}>
                  <div className='result-row'>
                    <span>
                      {ellipsis(view.conversation.title, 90)}
                    </span>
                    <strong>#{view.conversation.id}</strong>
                  </div>
                </div>
                <Icon className={'closeable-icon'} icon={XIcon} iconProps={{ size: 14 }} onClick={preventDefault(() => {
                  onDelete(view);
                  setInputFocus();
                })} />
              </li>
            );
          })
        }
      </ul>
    </>
  );
});
