import { useTranslation } from 'react-i18next';
import './header.search.results.scss';
import React, { useMemo } from 'react';
import { IElasticPreviewContact, IElasticSearchResult } from 'atlas-shared';
import { HeaderSearchResultsContact } from './header.search.results.contact';
import { NavigateFunction } from 'react-router';

interface IProps {
  contacts: IElasticSearchResult<IElasticPreviewContact>;
  navigate: NavigateFunction;
}
export const HeaderSearchResultsContacts = React.memo(({ contacts, navigate }: IProps) => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    if (contacts.items.length < 2)
      return contacts.items;

    const top_score = contacts.items[0].score;

    console.log('top_score', top_score);

    return contacts.items.filter(item => item.score > top_score * .6);
  }, [contacts.items]);

  return (
    <>
      <>
        {
          items.map((contact) => <HeaderSearchResultsContact t={t} item={contact} navigate={navigate} />)
        }
      </>
    </>
  );
});
