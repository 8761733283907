import { TFunction } from 'i18next';

export * from './abstract/journey_trigger.list.column.abstract';
import { JourneyTriggerListColumns as JourneyTriggerListColumnsBase } from './abstract/journey_trigger.list.column.abstract';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IJourneyStore, IOrganizationStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';
import { IPreviewJourneyTrigger, TJourneyTriggerSortableListCol } from 'atlas-shared/dist';
import { Icon } from '@Components';
import { EyeIcon } from '@Assets';
import { Tooltip } from 'antd';

export const JourneyTriggerListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, journeies: IJourneyStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewJourneyTrigger) => React.ReactElement> = [], filters: IWithListProps<IPreviewJourneyTrigger, TJourneyTriggerSortableListCol>['filters'], sorter: IWithListProps<IPreviewJourneyTrigger, TJourneyTriggerSortableListCol>['sorter']): ColumnsType<IPreviewJourneyTrigger> => {
  return JourneyTriggerListColumnsBase(t, navigate, showDeleted, organizations, journeies, abilities, auth, [
    (row: IPreviewJourneyTrigger) => {
      return <Tooltip overlayClassName={'table-tooltip-custom'} arrowPointAtCenter title={t('HIGHLIGHT_IN_JOURNEY')}
        placement="bottom">
        <div className={'table-icon-wrp'}>
          <Icon icon={EyeIcon} iconProps={{ size: 14 }} onClick={_ => navigate(`/admin/journey/${row.journey_id}/edit?highlight=${row.node_id}`) }/>
        </div>
      </Tooltip>;
    },
    ...buttons
  ], filters, sorter);
};
