import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchVoiceRecordingTranscriptSettings, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useVoiceRecordingTranscriptSettings = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_recording_transcript_settings = useSelector((store: IReducerStore) => store.voice_recording_transcript_settings);

  useEffect(() => {
    if (!initiated && autoload && !voice_recording_transcript_settings.loaded && !voice_recording_transcript_settings.loading) {
      initiated = true;
      dispatch(actionFetchVoiceRecordingTranscriptSettings());
    }
  }, [autoload, voice_recording_transcript_settings.loaded, voice_recording_transcript_settings.loading, dispatch]);

  return voice_recording_transcript_settings;
};
