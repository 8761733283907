import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './conversation.message.voice_call.scss';
import {
  getContactFullName,
  highlightClassNames,
  secondsToTime,
  timeDiff,
  timeSince,
  TranslateAndDisplayPhoneNumber
} from '@Utils';
import { useTranslation } from 'react-i18next';
import {
  DirectionEnum,
  IPreviewAgentprofile,
  ISharedFullConversation,
  TConversationVoiceCall,
  Undefinable,
  VoiceCallTransferTypeEnum,
  IPreviewVoiceCallBridge,
  IPreviewUser,
  Nullable,
  ISharedVoiceRecordingTranscript
  , ASTERISK_LANGUAGE_OPTIONS, CrudEnum, PermissionEnum, VoiceRecordingTranscriptTriggerEnum } from 'atlas-shared';
import {
  THighlightedMessage,
  useAbilities,
  useAgentprofiles,
  useQueues,
  useSipUsers,
  useUsers,
  useVoiceRoutes
} from '@Hooks';
import { IVoiceSessionObject, SipUser, getSipUsersWrapper } from 'SIP';
import { IAuth } from '@Store';
import {
  Avatar,
  ConversationMessageReplies,
  ConversationMessageReply,
  Icon,
  IconText,
  Timer
} from '@Components';
import { VoiceCallController } from './voice_call.controller';
import { PhoneIconCircled } from '@Assets/icons/channels';
import {
  ActivityIcon,
  ClockIcon,
  EditIcon,
  GlobeIcon,
  InboxIcon,
  SmartphoneIcon, StarIcon,
  UserIcon,
  VoiceArrowIcon,
  VoiceMergeIcon
} from '@Assets';
import { ConversationMessageVoiceCallVoiceRecording } from './conversation.message.voice_call.voice_recording';
import { ConversationMessageVoiceCallVoicemail } from './conversation.message.voice_call.voicemail';
import { VoiceCallTimeline } from './voice_call.timeline';
import { ConversationMessageVoiceCallVoiceRecordingTranscript } from './conversation.message.voice_call.voice_recording_transcript';
import { FormSelect } from 'atlas-form';

import { patchVoiceCall } from '@Api';

interface IProps {
  message: TConversationVoiceCall;
  conversation: ISharedFullConversation;
  auth: IAuth;
  mini?: boolean;
  absolute?: boolean;
  head?: boolean;
  highlightedMessage: THighlightedMessage;
}

interface ICaller {
  title?: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  agent?: React.ReactElement;
}

export const ConversationMessageVoiceCall = React.memo(({ message, mini = false, auth, conversation, head = true, absolute = false, highlightedMessage }: IProps) => {
  const { t } = useTranslation();
  const sipUserWrapper = getSipUsersWrapper();
  const sip_user: Undefinable<SipUser> = sipUserWrapper?.getSipUser(message.voice_asterisk_id)?.getSipUser();
  const voice_session: Undefinable<IVoiceSessionObject> = sip_user?.getSessionBySharedVoiceCall(message);
  const abilities = useAbilities();
  const [canRecord, setCanRecord] = useState<boolean>(false);
  const [agentprofile, setAgentprofile] = useState<IPreviewAgentprofile>();
  const agentprofiles = useAgentprofiles();
  const [timelineActive, setTimelineActive] = useState<boolean>(false);
  const [activeBridge, setActiveBridge] = useState<IPreviewVoiceCallBridge>();
  const [voiceRecordingTranscriptId, setVoiceRecordingTranscriptId] = useState<Undefinable<ISharedVoiceRecordingTranscript['id']>>();
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [editLanguage, setEditLanguage] = useState<boolean>(false);

  const queues = useQueues();
  const routes = useVoiceRoutes();
  const users = useUsers();

  const can_generate_transcript = useMemo(() => !!(
    queues.loaded &&
    message.queue_id &&
    abilities.canImplicit(null, CrudEnum.Update, PermissionEnum.VoiceRecordingTranscript) &&
    queues.dict[message.queue_id]?.voice_settings?.transcript &&
    (queues.dict[message.queue_id]?.voice_settings?.transcript_trigger || []).includes(VoiceRecordingTranscriptTriggerEnum.ClickToTranscript)
  ), [queues]);

  // necessary to get sip session changes
  /* eslint-disable unused-imports/no-unused-vars */
  const sip_users = useSipUsers();
  const controller = useMemo(() => {
    if (!activeBridge)
      return false;

    const transferred_bug = (activeBridge.transfer_type === VoiceCallTransferTypeEnum.Blind && !!activeBridge.transferred_from && activeBridge.user_id !== auth.user.id)
      || (activeBridge.transfer_type === VoiceCallTransferTypeEnum.Attended && activeBridge.user_id !== auth.user.id);

    return (activeBridge.user_id === auth.user.id || activeBridge.callee_user_id === auth.user.id) && !transferred_bug && <div className={'voice-ongoing-container'}>
      <VoiceCallController t={t} mini={mini} auth={auth} voice_call={message} voice_call_bridge={activeBridge} conversation={conversation} canRecord={canRecord} voice_session={voice_session} sip_user={sip_user} />
    </div>;
  }, [message, activeBridge, voice_session, canRecord, voice_session?.muted, voice_session?.held]);

  useEffect(() => {
    setActiveBridge(message.voice_call_bridges.find(bridge => bridge.leave_at === null) || undefined);
  }, [message.voice_call_bridges]);

  useEffect(() => {
    if (auth.user_status.agentprofile_id)
      setAgentprofile(agentprofiles.dict[auth.user_status.agentprofile_id]);
  }, [agentprofiles, auth.user_status.agentprofile_id]);

  useEffect(() => {
    setCanRecord(!!activeBridge && !!agentprofile?.settings?.start_recording && (activeBridge.user_id === auth.user.id || activeBridge.callee_user_id === auth.user.id));
  }, [agentprofile, auth.user.id, activeBridge?.callee_user_id, activeBridge?.user_id]);

  const getAgentCaller = useCallback((_bridge: IPreviewVoiceCallBridge, message: TConversationVoiceCall, receiver: boolean = false, route: boolean = false): ICaller => {
    let bridge = _bridge;
    let voice_call = message;

    if (bridge.transferred_from && bridge.transfer_type !== VoiceCallTransferTypeEnum.Blind) {
      voice_call = conversation.messages.find(voice_call => ((voice_call as TConversationVoiceCall).voice_call_bridges || []).find(b => b.id === bridge.transferred_from)) as unknown as TConversationVoiceCall;
      bridge = voice_call.voice_call_bridges.find(b => b.id === bridge.transferred_from) as unknown as IPreviewVoiceCallBridge;

      if (bridge.direction !== DirectionEnum.Internal)
        return getContactCaller(bridge, voice_call);
    }

    return {
      title: bridge.queue_id ? <IconText icon={InboxIcon} text={queues.dict[bridge.queue_id].title} ellipsis={mini ? 90 : false} /> : t('NO_QUEUE'),
      agent: bridge.user_id || (receiver && bridge.callee_user_id) ? <Avatar size={14} user={users.dict[(receiver ? bridge.callee_user_id || bridge.user_id : bridge.user_id) as IPreviewUser['id']]} postFixName={true} /> : undefined,
      subtitle: voice_call.voice_route_id && route ? <TranslateAndDisplayPhoneNumber t={t} phone_number={routes.dict[voice_call.voice_route_id]?.number} /> : undefined
    };
  }, [conversation, queues, routes]);

  const getContactCaller = useCallback((bridge: Nullable<IPreviewVoiceCallBridge>, message: TConversationVoiceCall): ICaller => {
    return {
      title: <TranslateAndDisplayPhoneNumber t={t} phone_number={bridge?.destination_phone || message.contact_phone} />,
      subtitle: conversation.contact?.phones.includes(bridge?.destination_phone || message.contact_phone) ? <IconText icon={UserIcon} className={'icon1'} iconProps={{ size: 14 }} text={getContactFullName(conversation.contact, t, true)} /> : undefined,
    };
  }, [conversation]);

  const listened_in = useMemo(() => {
    return message.voice_chanspy_calls.map(chanspy_call => {
      const listener = users.dict[chanspy_call.user_id];

      return <><Avatar size={14} user={listener} postFixName={true} /></>;
    });
  }, [message.voice_chanspy_calls]);

  const call_length = useMemo(() => <div className='call-length'>{
    <IconText icon={ClockIcon} text={message.end_time
      ? secondsToTime(timeDiff(message.created_at, message.end_time))
      : <Timer base={message.created_at} format="HH:mm:ss" default_value='00:00' ignoreZeros={true}/>
    } tooltip={{ title: t('CALL_LENGTH') }}/>
  }</div>, [message.created_at, message.end_time]);

  const pre_queue_time = useMemo(() => message.direction === DirectionEnum.InBound && <div className='pre-queue-time'>{
    <IconText icon={ClockIcon} text={message.queue_join_time || message.end_time
      ? secondsToTime(timeDiff(message.created_at, (message.queue_join_time || message.end_time) as any))
      : <Timer base={message.created_at} format="HH:mm:ss" default_value='00:00' ignoreZeros={true}/>
    } tooltip={{ title: t('PRE_QUEUE_TIME_LONG') }}/>
  }</div>, [message]);

  const language = useMemo(() => message.language && <div className='language'>
    {
      editLanguage
        ? <FormSelect
          value={message.language}
          options={ASTERISK_LANGUAGE_OPTIONS.map(l => ({ key: l, title: t(`LANGUAGE_${l.toUpperCase()}`) }))}
          onChange={language => {
            patchVoiceCall(message.organization_id, message.id, { language })
              .then(() => {
                setEditLanguage(false);
              });
          }}
        />
        : <IconText
          icon={GlobeIcon}
          text={message.language.toUpperCase()}
          tooltip={{ title: `${t('LANGUAGE')}: ${t(`LANGUAGE_${message.language.toUpperCase()}`)}` }}
        />
    }
    <span className='edit'>
      <Icon
        icon={EditIcon}
        onClick={() => { setEditLanguage(!editLanguage); }}
      />
    </span>
  </div>, [message, editLanguage]);

  const caller = useMemo(() => {
    switch (message.direction) {
    case DirectionEnum.InBound:
      return getContactCaller(null, message);
    case DirectionEnum.Outbound:
      return message.voice_call_bridges.length ? getAgentCaller(message.voice_call_bridges[0], message, false, true) : undefined;
    case DirectionEnum.Internal:
      return message.voice_call_bridges.length ? getAgentCaller(message.voice_call_bridges[0], message) : undefined;
    }

    return undefined;
  }, [message, conversation, queues, routes, users]);

  return (
    <div className={`conversation-message conversation-message-voice-call ${message.direction === DirectionEnum.InBound ? 'inbound' : 'outbound'}${mini ? ' mini' : ''}${absolute ? ' absolute' : ''}${highlightClassNames(message, highlightedMessage)}`}>
      <div className='conversation-message-content'>
        {mini && !absolute && <Icon icon={PhoneIconCircled} iconProps={{ size: 10, padding: 3 }} className='channel-icon' tooltip={{ title: t('VOICE_CALL') }}/>}
        {!absolute && <ConversationMessageReply t={t} auth={auth} message={message} conversation={conversation} mini={mini}/>}
        {
          head && <>
            <span className={'head'}>
              <strong>{timeSince(t, new Date(message.created_at), true)}</strong>
              <Icon
                icon={ActivityIcon}
                className={`toggle-timeline${timelineActive ? ' active' : ''}`}
                onClick={_ => setTimelineActive(!timelineActive)}
                tooltip={{
                  title: t('TIMELINE')
                }}
              />
              {!absolute && !message.end_time && <div className='call-ongoing'></div>}
              {call_length}
              {pre_queue_time}
              {language}
            </span>
          </>
        }
        {!absolute && <div className='call-data'>
          <div className='call-flow'>
            <div className='call-caller'>
              <div>{caller?.title}</div>
              <div>{caller?.subtitle}</div>
              <div>{caller?.agent}</div>
            </div>
            <div className='call-bridges'>
              {!message.voice_call_bridges.length && message.direction === DirectionEnum.InBound && message.voice_route_id && routes.loaded && routes.dict[message.voice_route_id] && <div className='call-bridge'>
                <div className='call-arrow'>
                  <Icon icon={VoiceArrowIcon} iconProps={{ size: 21 }}/>
                </div>
                <div className='call-callee'>
                  <div><TranslateAndDisplayPhoneNumber t={t} phone_number={routes.dict[message.voice_route_id].number} /></div>
                </div>
              </div>}
              {message.voice_call_bridges.map((bridge, i) => {
                const callee = message.direction === DirectionEnum.Outbound && !i ? getContactCaller(bridge, message)
                  : (bridge.destination_phone || '').startsWith('+') ? getContactCaller(bridge, message) : getAgentCaller(bridge, message, true, true);

                return <div className='call-bridge'>
                  {bridge.leave_at && !bridge.answer_time && <div className='bridge-abandoned'></div>}
                  {!bridge.leave_at && <div className='bridge-ongoing'></div>}
                  <div className='call-arrow'>
                    <Icon icon={bridge.transfer_type && !!bridge.transferred_from/* === VoiceCallTransferTypeEnum.Attended*/ ? VoiceMergeIcon : VoiceArrowIcon}
                      iconProps={{ size: 21 }}/>
                  </div>
                  <div className='bridge-callee'>
                    <div>{callee?.title}</div>
                    {bridge.twin_to && <div title={t('TWINNED_TO')} className='twin-to'><IconText icon={SmartphoneIcon} text={<TranslateAndDisplayPhoneNumber t={t} phone_number={bridge.twin_to} />} /></div>}
                    {!i && <div>{callee?.subtitle}</div>}
                    <div>{callee?.agent}</div>
                  </div>
                  <div className='bridge-right'>
                    <div className='bridge-recordings'>
                      {!absolute && message.voice_recordings.filter(recording => recording.voice_call_bridge_id === bridge.id).map(recording => <ConversationMessageVoiceCallVoiceRecording
                        key={recording.id}
                        recording={recording}
                        voice_call={message}
                        voice_call_bridge={message.voice_call_bridges.find(bridge => bridge.id === recording.voice_call_bridge_id)}
                        agentprofile={agentprofile}
                        auth={auth}
                        abilities={abilities}
                        users={users}
                        size={mini ? 's' : 'm'}
                        transcript={{
                          get: voiceRecordingTranscriptId,
                          set: setVoiceRecordingTranscriptId
                        }}
                        setCurrentTime={setCurrentTime}
                        can_generate_transcript={can_generate_transcript}
                      />)}
                    </div>
                    {!mini && <div className='bridge-info'>

                      {bridge.points_talk && <div className='points-talk'>
                        <div className='title'></div>
                        <div className='value'>
                          <Icon
                            icon={StarIcon}
                            tooltip={{ title: `${t('POINTS_TALK')}: ${bridge.points_talk}p` }}
                            iconProps={{ style: { stroke: 'gold', fill: 'gold' } }}
                          />
                        </div>
                      </div>}
                      {bridge.user_id && <div className='call-agent'>
                        <div className='title'>{t('AGENT')}</div>
                        <div className='value'><Avatar size={14} user={users.dict[bridge.user_id]} postFixName={true} /></div>
                      </div>}
                      {message.start_time && !bridge.transferred_from && <div className='call-waiting'>
                        <div className='title'>{t('WAIT_TIME')}</div>
                        <div>
                          <IconText icon={ClockIcon}
                            text={bridge.leave_at || bridge.answer_time ? secondsToTime(timeDiff(bridge.created_at, (bridge.answer_time || bridge.leave_at) as string)) :
                              <Timer base={bridge.created_at} format="HH:mm:ss" default_value='00:00'
                                ignoreZeros={true}/>}/>
                        </div>
                      </div>}
                      {bridge.answer_time && <div className='talk-time'>
                        <div className='title'>{t('TALK_TIME')}</div>
                        <div>
                          <IconText icon={ClockIcon}
                            text={bridge.leave_at ? secondsToTime(timeDiff(bridge.answer_time, bridge.leave_at)) :
                              <Timer base={bridge.answer_time} format="HH:mm:ss" default_value='00:00'
                                ignoreZeros={true}/>}/>
                        </div>
                      </div>}
                    </div>}
                  </div>
                </div>;
              })}
            </div>
          </div>
          {voiceRecordingTranscriptId && <ConversationMessageVoiceCallVoiceRecordingTranscript
            id={voiceRecordingTranscriptId}
            organization_id={message.organization_id}
            currentTime={currentTime}
          />}
        </div>
        }
        {controller}
        {!absolute && message.voice_recordings.filter(recording => !recording.voice_call_bridge_id).map(recording => <ConversationMessageVoiceCallVoiceRecording
          key={recording.id}
          recording={recording}
          voice_call={message}
          agentprofile={agentprofile}
          auth={auth}
          abilities={abilities}
          users={users}
          transcript={{
            get: voiceRecordingTranscriptId,
            set: setVoiceRecordingTranscriptId
          }}
          setCurrentTime={setCurrentTime}
          can_generate_transcript={can_generate_transcript}
        />)}
        {!absolute && message.voice_voicemail_messages.map(voice_voicemail_message => <ConversationMessageVoiceCallVoicemail
          key={voice_voicemail_message.id}
          users={users}
          voice_voicemail_message={voice_voicemail_message}
          call={message}
          agentprofile={agentprofile}
          auth={auth}
          abilities={abilities}
        />)}
        {mini && !absolute && !!message.voice_chanspy_calls.length && <div className='listened-in'>
          <span className='title'>{t('LISTENED_IN')}</span> {listened_in}
        </div>}
        {timelineActive && <VoiceCallTimeline organization_id={message.organization_id} voice_call_id={message.id} voice_call={message}/>}
      </div>
      <ConversationMessageReplies message={message} conversation={conversation} highlightedMessage={highlightedMessage} />
    </div>
  );
});
