import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedUpdateVoiceRecordingTranscriptSetting, ISharedVoiceRecordingTranscriptSetting, IPreviewOrganization, EnumOptions, VoiceRecordingTranscriptModelEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IVoiceRecordingTranscriptSettingFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const VoiceRecordingTranscriptSettingFormOptions = (voice_recording_transcript_setting: ISharedVoiceRecordingTranscriptSetting, props: IVoiceRecordingTranscriptSettingFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.VoiceRecordingTranscriptSetting, {})), 'organization_id', false), 'organization'),
  model_en: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
  model_se: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
  model_fi: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
  model_dk: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
  model_de: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
  model_no: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
  model_pl: EnumOptions('VOICE_RECORDING_TRANSCRIPT_MODEL', VoiceRecordingTranscriptModelEnum, t),
});

export const VoiceRecordingTranscriptSettingComponents: IForm['components'] = {};
export const VoiceRecordingTranscriptSettingAdditionalParams: (auth: IAuth, props: IVoiceRecordingTranscriptSettingFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceRecordingTranscriptSettingFormOptionsProps) => ({
});

export const VoiceRecordingTranscriptSettingEditForm = (voice_recording_transcript_setting: ISharedVoiceRecordingTranscriptSetting, props: IVoiceRecordingTranscriptSettingFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_recording_transcript_setting_edit_${voice_recording_transcript_setting.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === voice_recording_transcript_setting.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'model' },
    { ref: 'find_replace' }
  ]
});

export const VoiceRecordingTranscriptSettingEditInitialValues = (voice_recording_transcript_setting: ISharedVoiceRecordingTranscriptSetting): ISharedUpdateVoiceRecordingTranscriptSetting => ({
  model: voice_recording_transcript_setting.model,
  find_replace: voice_recording_transcript_setting.find_replace
});

export const onVoiceRecordingTranscriptSettingFormSaved = (t: TFunction, action: 'edit' | 'add', voice_recording_transcript_setting: ISharedVoiceRecordingTranscriptSetting, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_recording_transcript_settings'));
};
