import { RestRequest } from '@Utils';
import { IPreviewJourneyTrigger, ISharedJourneyTrigger } from 'atlas-shared';

export const fetchJourneyTriggers = async (): Promise<Array<IPreviewJourneyTrigger>> => {
  return RestRequest.get<Array<IPreviewJourneyTrigger>>('journey_trigger', { socket_id: global.socketId });
};

export const fetchJourneyTrigger = async (id: IPreviewJourneyTrigger['id']): Promise<ISharedJourneyTrigger> => {
  return RestRequest.get<ISharedJourneyTrigger>(`journey_trigger/${id}`, { socket_id: global.socketId });
};
