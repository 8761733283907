import React, { useCallback, useEffect, useRef, useState } from 'react';
import './timeline.scss';
import { Tooltip, Checkbox } from 'antd';
import {
  ISharedVoiceCall,
  IConversationTimeline,
  JourneyTimelineEventType,
  ISharedJourney
} from 'atlas-shared';
import { ITimelineEvent } from './timeline.event.interface';
import moment from 'moment';
import { uniq } from '@Utils';
import { useTranslation } from 'react-i18next';
import { useJourneies } from '@Hooks';
import { useNavigate } from 'react-router-dom';

const CheckboxGroup = Checkbox.Group;

interface ITimeline {
  start: number;
  end: number;
  day: string;
  event_groups: Array<ITimelineEventGroup>;
}

interface ITimelineEventGroup {
  ts: number;
  events: Array<TTimelineGroupEventEvent>;
}

type TTimelineGroupEventEvent = { translated: ITimelineEvent } & IConversationTimeline;
type TTimelineEvent = { translated: ITimelineEvent } & IConversationTimeline;

interface IProps {
  events: Array<TTimelineEvent>;
  voice_call?: ISharedVoiceCall;
}

const EVENT_COLOR = {
  [JourneyTimelineEventType.Audit]: 'red',
  [JourneyTimelineEventType.Voice]: 'orange',
  [JourneyTimelineEventType.Notification]: 'yellow',
  [JourneyTimelineEventType.JourneyJob]: 'purple',
};

export const Timeline = React.memo(({ events }: IProps) => {

  const ref = useRef(null);
  const { t } = useTranslation();
  const journeys = useJourneies();
  const navigate = useNavigate();
  const [timelines, setTimelines] = useState<Array<ITimeline>>([]);
  const [filteredEvents, setFilteredEvents] = useState<Array<TTimelineEvent>>([]);
  const [availableEventTypes, setAvailableEventTypes] = useState<Array<{label: string; value: JourneyTimelineEventType}>>([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState<Array<JourneyTimelineEventType>>([]);
  const [availableJourneys, setAvailableJourneys] = useState<Array<{label: string; value: string}>>([]);
  const [selectedJourneys, setSelectedJourneys] = useState<Array<ISharedJourney['id']>>([]);

  const filterEventTypesChanged = useCallback((selected: Array<any>) => {
    setSelectedEventTypes(selected);
  }, []);

  const filterJourneysChanged = useCallback((selected: Array<any>) => {
    setSelectedJourneys(selected);
  }, []);

  useEffect(() => {

    setAvailableEventTypes(uniq(events.map(e => e.type)).map(type => ({
      label: t(`TIMELINE_EVENT_TYPE_${type.toUpperCase()}`),
      value: type,
      style: {
        borderBottom: `2px solid ${EVENT_COLOR[type]}`
      }
    })));

    setAvailableJourneys(uniq(events.filter(e => e.journey_id).map(e => e.journey_id)).map(value => ({
      label: journeys.dict[value]?.title || value.toString(),
      value
    })));

  }, [journeys, events]);

  useEffect(() => {
    if (!selectedJourneys.length)
      setSelectedJourneys(availableJourneys.map(e => +e.value));
  }, [selectedJourneys, availableJourneys]);

  useEffect(() => {
    if (!selectedEventTypes.length)
      setSelectedEventTypes(availableEventTypes.map(e => e.value));
  }, [selectedEventTypes, availableEventTypes]);

  useEffect(() => {
    setFilteredEvents(events.filter(e => selectedEventTypes.includes(e.type) && (!e.journey_id || selectedJourneys.includes(e.journey_id))));
  }, [events, selectedEventTypes, selectedJourneys]);

  useEffect(() => {

    const _timelines: Array<ITimeline> = [];
    const days = uniq(filteredEvents.map(e => moment(e.dt).format('YYYY-MM-DD')));

    days.forEach(day => {

      let last_ts = 0;

      const _events = filteredEvents.filter(e => moment(e.dt).format('YYYY-MM-DD') === day);
      const timeline: ITimeline = {
        day,
        start: new Date(_events[0].dt).getTime(),
        end: new Date(_events[_events.length - 1].dt).getTime(),
        event_groups: []
      };

      const diff_sec = timeline.end - timeline.start;
      const space = diff_sec / 120;

      _events.forEach(e => {
        const dt = new Date(e.dt);
        const ts = dt.getTime();
        let index = timeline.event_groups.length - 1;
        let new_item = (ts - last_ts) > space;
        let full = !new_item && timeline.event_groups[index].events.length > 9;

        if (new_item || full) {
          timeline.event_groups.push({
            ts: full ? last_ts + (space * .75) : ts,
            events: []
          });
          index++;
          last_ts = ts;
        }

        timeline.event_groups[index].events.push({
          ...e,
          dt
        });

      });

      if (timeline.event_groups.length)
        _timelines.push(timeline);
    });

    setTimelines(_timelines);

  }, [filteredEvents]);

  return <div className={`journey-timelines${timelines.length > 1 ? ' multiple' : ' multiple'}`} ref={ref}>
    <div className='event-filter'>
      {
        availableEventTypes.length > 1 && <div className='event-type-filter'>
          <CheckboxGroup options={availableEventTypes} value={selectedEventTypes} onChange={filterEventTypesChanged} />
        </div>
      }
      {
        selectedEventTypes.includes(JourneyTimelineEventType.JourneyJob) && availableJourneys.length > 1 && <div className='journey-filter'>
          <span className={'title'}>{t('JOURNEY')}</span>
          <div>
            <CheckboxGroup options={availableJourneys} value={selectedJourneys} onChange={filterJourneysChanged} />
          </div>
        </div>
      }
    </div>
    {
      timelines.map(({ event_groups, start, end, day }) => {
        return <div className='journey-timeline'>
          <div className='journey-timeline-date'>{day}</div>
          <div className='wrapper'>
            {event_groups.map(event => <Tooltip placement='topLeft' overlayClassName='conversation-timeline-tooltip' color='var(--blue-grey)' style={{ width: 360, maxWidth: 360 }} title={<ul>
              {event.events.map(e => {
                const { title, description, journey_id, journey_trigger } = e.translated;

                return <li
                  className={`type-${e.type}`}
                  style={{
                    borderColor: EVENT_COLOR[e.type]
                  }}
                >
                  <div className='row'>
                    <div className='time'>{e.dt.toLocaleTimeString()}</div>
                    {journey_id && <div
                      className='journey'
                      title={'Journey id'}
                      onClick={() => {
                        navigate(`/admin/journey/${e.journey_id}/edit?highlight=${journey_trigger?.node_id || ''}`);
                      }}
                    >
                      {journey_id}
                    </div>}
                    <div className='title'>{title}</div>
                  </div>
                  {description && <div className='row'>
                    <div className='description'>{description.split('\n').map(l => <>{l}<br /></>)}</div>
                  </div>}
                </li>;
              })}
            </ul>}><div className='dot' style={{
                left: `${(event.ts - start) / (end - start) * 100}%`,
                background: EVENT_COLOR[event.events[0].type]
              }}>{event.events.length > 1 ? event.events.length : ''}</div></Tooltip>)}
            <div className='line'>
              {event_groups.length > 1 && <>
                <span className='time' style={{ left: 0, marginLeft: -8 }}>{event_groups[0].events[0].dt.toLocaleTimeString()}</span>
                <span className='time' style={{ left: '100%', marginLeft: -32 }}>{event_groups[event_groups.length - 1].events[event_groups[event_groups.length - 1].events.length - 1].dt.toLocaleTimeString()}</span>
              </>}
            </div>
          </div>
        </div>;
      })
    }</div>;
});
