import { IPreviewVoiceRecordingTranscriptSetting } from 'atlas-shared';
import { IVoiceRecordingTranscriptSettingStore, VoiceRecordingTranscriptSettingActions, TVoiceRecordingTranscriptSettingActionTypes } from '@Store';

export const VoiceRecordingTranscriptSettingReducer = (state: IVoiceRecordingTranscriptSettingStore = {
  loaded: false,
  loading: false,
  voice_recording_transcript_settings: [],
  dict: {}
}, action: TVoiceRecordingTranscriptSettingActionTypes): IVoiceRecordingTranscriptSettingStore => {
  switch (action.type) {
  case VoiceRecordingTranscriptSettingActions.LOADING:
    return { ...state, loading: true };
  case VoiceRecordingTranscriptSettingActions.SET_STATE:
    const dict: IVoiceRecordingTranscriptSettingStore['dict'] = {};

    action.payload.forEach((voice_recording_transcript_setting: IPreviewVoiceRecordingTranscriptSetting) => dict[voice_recording_transcript_setting.id] = voice_recording_transcript_setting);

    return { ...state, loaded: true, loading: false, voice_recording_transcript_settings: action.payload, dict };
  case VoiceRecordingTranscriptSettingActions.ADD_STATE:
    return { ...state, voice_recording_transcript_settings: [...state.voice_recording_transcript_settings, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceRecordingTranscriptSettingActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_recording_transcript_settings: state.voice_recording_transcript_settings.filter((item: IPreviewVoiceRecordingTranscriptSetting) => item.id !== action.payload), dict };
  }
  case VoiceRecordingTranscriptSettingActions.UPDATE_STATE:
    const voice_recording_transcript_settings = [...state.voice_recording_transcript_settings];
    const index = state.voice_recording_transcript_settings.findIndex((voice_recording_transcript_setting: IPreviewVoiceRecordingTranscriptSetting) => voice_recording_transcript_setting.id === action.payload.id);

    if (!~index)
      voice_recording_transcript_settings.push(action.payload);
    else
      voice_recording_transcript_settings[index] = action.payload;

    return { ...state, voice_recording_transcript_settings, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
