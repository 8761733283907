import React from 'react';
import { TFunction } from 'i18next';
import { IPreviewContact, Nullable, SearchSectionEnum } from 'atlas-shared/dist';
import { NoResult } from '@Components';

interface IProps {
  t: TFunction;
  section: Nullable<SearchSectionEnum>;
  keyword?: string;
  contact_id?: IPreviewContact['id'];
  style?: React.CSSProperties;
  text?: string;
}

export const SearchNoResult = React.memo((props: IProps) => {
  const { t, section, keyword, contact_id, style, text } = props;
  const SECTION_MAP = {
    [SearchSectionEnum.Conversations]: 'CONVERSATIONS',
    [SearchSectionEnum.Contacts]: 'CONTACTS',
  };

  const _text = text || t(`NO_${(section ? SECTION_MAP[section] : Object.values(SECTION_MAP).join('_OR_'))}_WERE_FOUND`, { keyword });

  return <NoResult description={_text} style={style} />;
});
